const runFinalCardsUseCase = ({ month, year }, { observer, monthCloseRepo }) => {
  monthCloseRepo.runFinalCards(
    { month, year },
    {
      success: (response) => {
        observer.successfulRunFinalCards(response.monthClose)
      },
      failure: (e) => {
        observer.errorWhileRunningFinalCards()
      }
    }
  )
}

export default runFinalCardsUseCase
