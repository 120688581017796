import moment from 'moment/moment'

function getWeeksInYear(year) {
  const startOfYear = moment(year, 'YYYY').startOf('year')
  const startOfNextYear = moment(year + 1, 'YYYY').startOf('year')
  return startOfNextYear.diff(startOfYear, 'weeks')
}

export const getDateRange = (weekNumber, inputMonth, inputYear = new Date().getFullYear()) => {
  // Check weekNumber value
  if (weekNumber === undefined || weekNumber === null) {
    return '--'
  }

  const lastWeekNumberOfYear = getWeeksInYear(inputYear)

  // Check if weekNumber exceeds the total weeks in the year
  if (weekNumber > lastWeekNumberOfYear) {
    return '--'
  }

  // Note: in JavaScript, months are 0-indexed, so we need to adjust inputMonth
  const adjustedMonth = inputMonth - 1

  // Get start and end dates of the week
  let startDateObj = moment().year(inputYear).week(weekNumber).startOf('week')
  let endDateObj = moment().year(inputYear).week(weekNumber).endOf('week')

  // Adjust start and end dates based on inputMonth
  if (startDateObj.month() < adjustedMonth) {
    startDateObj = moment().year(inputYear).month(adjustedMonth).startOf('month')
  }
  if (endDateObj.month() > adjustedMonth) {
    endDateObj = moment().year(inputYear).month(adjustedMonth).endOf('month')
  }

  return `${startDateObj.format('MM/DD/YY')} - ${endDateObj.format('MM/DD/YY')}`
}

export const getNumberOfDays = (weekNumber, inputMonth, inputYear = new Date().getFullYear()) => {
  if (weekNumber === undefined || weekNumber === null) {
    return '--'
  }

  const lastWeekNumberOfYear = moment(`12-31-${inputYear}`, 'MM-DD-YYYY').isoWeek()

  if (weekNumber > lastWeekNumberOfYear) {
    return '--'
  }

  let startDateObj = moment(`01-01-${inputYear}`).day('Sunday').week(weekNumber)
  let endDateObj = moment(`01-01-${inputYear}`).day('Saturday').week(weekNumber)

  if (startDateObj.clone().month() + 1 < inputMonth) {
    startDateObj = moment(`${inputMonth}-01-${startDateObj.clone().year()}`)
  }

  if (endDateObj.clone().month() + 1 > inputMonth) {
    endDateObj = startDateObj.clone().endOf('month')
  }

  return endDateObj.diff(startDateObj, 'days') + 1
}
