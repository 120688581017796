import React, { useState } from 'react'

import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'

import { NavLink, useHistory, useLocation } from 'react-router-dom'
import Routes from 'routes'
import forgotPasswordUseCase from '../use-cases/forgot-password-use-case'
import LoadingSpinner from 'components/common/LoadingSpinner'
import Logo from 'components/common/Logo'

function ForgotPassword({ repoFactory }) {
  const location = useLocation()
  const receivedValue = location.state && location.state.value

  const [username, setUsername] = useState(receivedValue)
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)
  const history = useHistory()

  const handleForgotPassword = (e) => {
    e.preventDefault()
    setIsLoading(true)
    forgotPasswordUseCase(
      { username },
      {
        authRepo: repoFactory.authRepo(),
        observer: {
          forgotPasswordSuccess: (response) => {
            setIsLoading(false)
            setError(false)
            history.push(Routes.RESET_PASSWORD.PATH, { value: username })
          },
          forgotPasswordFailed: () => {
            setIsLoading(false)
            setError(true)
          }
        }
      }
    )
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <Row className="justify-content-md-center pt-5">
        <Col xs={12} md={5}>
          <div className="text-center">
            <Logo alt="logo" className="mb-5" />
          </div>
          <Card className="shadow-sm">
            <Card.Body>
              <Form>
                <h4 className="text-center mb-3">FORGOT PASSWORD</h4>
                <Alert variant="info" className="text-md">
                  Please enter your username and we will send you a code to reset your password.
                </Alert>
                {error && (
                  <Alert variant="danger" className="text-md">
                    Unable to reset password, please check your username and try again
                  </Alert>
                )}
                <Form.Group className="mb-3">
                  <Form.Control
                    type="username"
                    placeholder="Username"
                    defaultValue={username}
                    onChange={(e) => {
                      setUsername(e.target.value)
                    }}
                  />
                </Form.Group>
                <div className="d-flex justify-content-between align-items-center">
                  <NavLink
                    to={{
                      pathname: Routes.RESET_PASSWORD.PATH,
                      state: { value: username }
                    }}
                    className="text-sm"
                  >
                    I already have a code
                  </NavLink>

                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleForgotPassword}
                    disabled={!username || username?.length < 4}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <div className="text-center mt-4">
            <NavLink to="/login" className="text-sm">
              Go to login page
            </NavLink>
          </div>
        </Col>
      </Row>
    </>
  )
}
export default ForgotPassword
