function AuthRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.loginUser = ({ username, password }, { success, failure }) => {
    const query = `mutation ($username: String!, $password: String!) {
              loginUser(
                  user: {
                  username: $username,
                  password: $password
              })
              {
                ChallengeName
                Session
                UserRole
                ChallengeParameters {
                  USER_ID_FOR_SRP
                  requiredAttributes
                  userAttributes
                }
                AuthenticationResult {
                  AccessToken
                  ExpiresIn
                  TokenType
                  RefreshToken
                  IdToken
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            username,
            password
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.changePasswordChallenge = ({ username, password, session }, { success, failure }) => {
    const query = `mutation ($username: String!, $password: String!, $session: String!) {
              completePasswordChallenge(
                  user: {
                      username: $username,
                      password: $password,
                      session: $session
                  })
                  {
                    ChallengeName
                    Session
                    UserRole
                    ChallengeParameters {
                      USER_ID_FOR_SRP
                      requiredAttributes
                      userAttributes
                    }
                    AuthenticationResult {
                      AccessToken
                      ExpiresIn
                      TokenType
                      RefreshToken
                      IdToken
                    }
                  }
          }`

    const data = {
      query,
      variables: {
        username,
        password,
        session
      }
    }

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.forgotPassword = ({ username }, { success, failure }) => {
    const query = `mutation ($username: String!) {
              forgotPassword(username: $username)
              {
                CodeDeliveryDetails {
                  AttributeName
                  DeliveryMedium
                  Destination
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            username
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.confirmPassword = ({ username, newPassword, confirmCode }, { success, failure }) => {
    const query = `mutation ($username: String!, $newPassword: String!, $confirmCode: String!) {
              confirmPassword(forgotPassword: {
                      username: $username,
                      newPassword: $newPassword,
                      confirmCode: $confirmCode
                  })
              {
                dummyField
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            username,
            newPassword,
            confirmCode
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.changePassword = ({ accessToken, newPassword, password }, { success, failure }) => {
    const query = `mutation ($accessToken: String!, $newPassword: String!, $password: String!) {
              changePassword(changePassword: {
                      accessToken: $accessToken,
                      newPassword: $newPassword,
                      password: $password
                  })
              {
                dummyField
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            accessToken,
            newPassword,
            password
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default AuthRepo
