function SorRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.uploadSOR = ({ token, fileName, fileType, fileContent }, { success, failure }) => {
    const query = `mutation ($token: String!, $file: File!) {
                      uploadSOR(sorUpload: {file: $file, token: $token}) {
                        status
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            file: {
              fileName,
              fileType,
              fileContent
            }
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default SorRepo
