const resetPasswordUseCase = ({ username, newPassword, confirmCode }, { observer, authRepo }) => {
  authRepo.confirmPassword(
    { username, newPassword, confirmCode },
    {
      success: (response) => {
        if (!response?.confirmPassword) {
          observer.confirmPasswordFailed()
          return
        }
        observer.confirmPasswordSuccess(response.confirmPassword)
      },
      failure: () => {
        observer.confirmPasswordFailed()
      }
    }
  )
}

export default resetPasswordUseCase
