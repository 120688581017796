const saveDashboardList = (updateQSList, { observer, biRepo }) => {
  biRepo.saveDashboard(updateQSList, {
    success: (data) => {
      observer.success(data)
    },
    failure: (e) => {
      observer.error(e)
    }
  })
}

export default saveDashboardList
