function SpacesRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.listSpaces = ({ year, token }, { success, failure }) => {
    const query = `query ($token: String!, $year: Int!) {
                          listSpaces(spaceSearch: {token: $token, year: $year}) {
                            data {
                                element1
                                element2
                                element3
                                element4
                                year
                                updatedTimeStamp
                                estimates {
                                  accountId
                                  environment
                                  accountDescription
                                  accountName
                                  status
                                  allocation {
                                    percentage
                                    startDate
                                    insertedDate
                                    endDate
                                    environment
                                  }
                                  breakdown {
                                    total
                                    startDate
                                    endDate
                                    estimateLink
                                    reference
                                  }
                                }
                            }
                            error {
                              code
                              message
                              statusCode
                            }
                          }
                        }
`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          const { listSpaces } = response.data.data
          success(listSpaces)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.createOrUpdateSpace = (
    { year, token, element1, element2, element3, element4, estimates },
    { success, failure }
  ) => {
    const query = `mutation ($token: String!, $year: Int!, $estimates: [EstimatesInput]!, $element1Id: String!, $element2Id: String!, $element3Id: String!, $element4Id: String!) {
              createOrUpdateSpaceDetails(space: {estimates: $estimates, element1Id: $element1Id, element2Id: $element2Id, element3Id: $element3Id, element4Id: $element4Id, token: $token, year: $year}) {
                data
                error {
                  code
                  message
                  statusCode
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year,
            element1Id: element1,
            element2Id: element2,
            element3Id: element3,
            element4Id: element4,
            estimates
          }
        }
      },
      {
        success: (response) => {
          const { createOrUpdateSpaceDetails } = response.data.data
          success(createOrUpdateSpaceDetails)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default SpacesRepo
