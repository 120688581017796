import { BudgetStatus } from 'Budgets/constants/BudgetStatus'

export const getColorCharts = (percentage) => {
  if (percentage < 25) {
    return 'green'
  } else if (percentage < 60) {
    return 'yellow'
  } else if (percentage < 90) {
    return 'orange'
  }

  return 'red'
}

export const getProductsFromBudgets = (budgets = []) => {
  const products = []
  budgets.forEach((budget) => {
    if (budget.hasOwnProperty('departments')) {
      budget.departments?.forEach((department) => {
        department.portfolios?.forEach((portfolio) => {
          portfolio.products?.forEach((product) => {
            products.push({ ...product })
          })
        })
      })
    } else if (budget.hasOwnProperty('portfolios')) {
      budget.portfolios?.forEach((portfolio) => {
        portfolio.products?.forEach((product) => {
          products.push({ ...product })
        })
      })
    } else if (budget.hasOwnProperty('products')) {
      budget.products?.forEach((product) => {
        products.push({ ...product })
      })
    } else {
      products.push({ ...budget })
    }
  })

  return products
}

export const getTotalBudgetAndSpend = (budgets = [], isMonthly, month = new Date().getMonth()) => {
  let totalBudget = 0
  let totalSpend = 0
  const products = getProductsFromBudgets(budgets)

  const approvedBudgets = products.filter((product) => product.status === BudgetStatus.APPROVED)

  if (isMonthly) {
    products.forEach((product) => {
      totalSpend += product.actuals?.[`_${month}`]?.cost || 0
    })
    approvedBudgets.forEach((product) => {
      totalBudget += product.budgets?.[`_${month}`] || 0
    })
  } else {
    products.forEach((product) => {
      totalSpend += Object.values(product.actuals).reduce((a, b) => a + (b.cost || 0), 0)
    })

    approvedBudgets.forEach((product) => {
      totalBudget += Object.values(product.budgets).reduce((a, b) => a + (b || 0), 0)
    })
  }

  return {
    totalBudget,
    totalSpend,
    percentage: totalBudget ? Math.floor((totalSpend / totalBudget) * 100) || 0 : 0
  }
}

function getCurrentQuarter(date = new Date()) {
  return Math.floor(date.getMonth() / 3 + 1)
}

export const getSpendQuarter = (budgets = []) => {
  const quarter = getCurrentQuarter()
  const startMonth = (quarter - 1) * 3
  const endMonth = startMonth + 2
  let totalSpendQuarter = 0
  const products = getProductsFromBudgets(budgets)

  products.forEach((product) => {
    for (let i = startMonth; i <= endMonth; i++) {
      totalSpendQuarter += product.actuals?.[`_${i}`].cost || 0
    }
  })

  return totalSpendQuarter
}

export const getUnallocatedBudget = (budgets = []) => {
  let totalBudget = 0
  let plannedBudget = 0
  const products = getProductsFromBudgets(budgets)

  products.forEach((product) => {
    totalBudget += product.amount || 0
    plannedBudget +=
      product.status === BudgetStatus.APPROVED ? Object.values(product.budgets).reduce((a, b) => a + (b || 0), 0) : 0
  })

  return totalBudget - plannedBudget
}

export const getEOYPlannedBudget = (budgets, month = new Date().getMonth()) => {
  let EOYPlanned = 0

  const calculateEOYPlanned = (products) => {
    products.forEach((product) => {
      const budgetCurrentMonth = product.budgets?.[`_${month}`] || 0
      const spendCurrentMonth = product.actuals?.[`_${month}`].cost || 0
      const varianceCurrentMonth = budgetCurrentMonth - spendCurrentMonth
      EOYPlanned += varianceCurrentMonth

      for (let i = month + 1; i < 12; i++) {
        EOYPlanned += product.budgets?.[`_${i}`] || 0
      }
    })
  }

  budgets.forEach((budget) => {
    if (budget) {
      if (budget.hasOwnProperty('departments')) {
        budget.departments?.forEach((department) =>
          department.portfolios?.forEach((portfolio) => calculateEOYPlanned(portfolio.products))
        )
      } else if (budget.hasOwnProperty('portfolios')) {
        budget.portfolios?.forEach((portfolio) => calculateEOYPlanned(portfolio.products))
      } else if (budget.hasOwnProperty('products')) {
        calculateEOYPlanned(budget.products)
      } else {
        calculateEOYPlanned([budget])
      }
    }
  })

  return EOYPlanned
}
