import React, { useState } from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import UpdatePassword from './UpdatePassword'
import UsernamePassword from './UsernamePassword'

import Logo from 'components/common/Logo'

function Login({ repoFactory }) {
  const [changePasswordChallenge, setChangePasswordChallenge] = useState(false)
  const [username, setUsername] = useState(null)
  const [challengePasswordSession, setChallengePasswordSession] = useState(null)

  return (
    <Row className="justify-content-md-center pt-5">
      <Col xs={12} md={5}>
        <div className="text-center">
          <Logo alt="logo" className="mb-5" height={50} />
        </div>
        <Card className="shadow-sm">
          <Card.Body>
            {changePasswordChallenge ? (
              <UpdatePassword username={username} session={challengePasswordSession} repoFactory={repoFactory} />
            ) : (
              <UsernamePassword
                username={username}
                setUsername={setUsername}
                setChangePasswordChallenge={setChangePasswordChallenge}
                setChallengePasswordSession={setChallengePasswordSession}
                repoFactory={repoFactory}
              />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Login
