import Roles from 'config/Roles'

const UserLevelMap = {
  [Roles.FINANCIAL_ADMINS]: 1,
  [Roles.BU_MANAGERS]: 1,
  [Roles.DEPARTMENT_MANAGERS]: 2,
  [Roles.PORTFOLIO_MANAGERS]: 3,
  [Roles.PRODUCT_MANAGERS]: 4
}

export default UserLevelMap
