import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import UserAllocation from './UserAllocation'
import Select from 'react-select'

const UsersWithNoAccess = ({
  usersWithNoBudget,
  isNewUser,
  selectedUserForMapping,
  repoFactory,
  sorElements,
  allocationYear,
  getUserElements
}) => {
  const [userForMapping, setUserForMapping] = useState(null)
  const [isSelectedUserNew, setIsSelectedUserNew] = useState(true)

  useEffect(() => {
    if (selectedUserForMapping) {
      setUserForMapping(selectedUserForMapping)
      setIsSelectedUserNew(isNewUser)
    }
  }, [isNewUser, selectedUserForMapping, setUserForMapping, setIsSelectedUserNew, usersWithNoBudget])

  const getUsersOptions = () => {
    return usersWithNoBudget.map((user) => ({
      value: user.Username,
      label: user.Username
    }))
  }

  return (
    <div className="users-with-no-access">
      <h1>User to Scope Mapping</h1>
      <Row>
        <Col md={4}>
          <Select
            classNamePrefix="select"
            placeholder="Select User"
            options={getUsersOptions()}
            onChange={(option) => {
              if (option) {
                const selectedUser = usersWithNoBudget.find((a) => a.Username === option.value)
                setUserForMapping(selectedUser)
                setIsSelectedUserNew(true)
              } else {
                setUserForMapping(null)
                setIsSelectedUserNew(false)
              }
            }}
            isClearable
          />
        </Col>
        <Col md={12} className="mt-3">
          {userForMapping && (
            <UserAllocation
              repoFactory={repoFactory}
              selectedUser={userForMapping}
              isNew={isSelectedUserNew}
              sorElements={sorElements}
              allocationYear={allocationYear}
              getUserElements={getUserElements}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default UsersWithNoAccess
