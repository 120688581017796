function MonthCloseRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.runFinalCards = ({ year, month }, { success, failure }) => {
    const query = `query ($month: Int!, $year: Int!) {
      monthClose(month: $month, year: $year) {
        dummyField
      }
    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            month,
            year
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.createInvoice = ({ invoice }, { success, failure }) => {
    const query = `
        mutation ($invoice: InvoiceInput!) {
          createInvoice(
            invoice: $invoice
          ) {
            data {
              breakdown {
                amount
                dateRange
                element1
                element4
              }
              credit
              id
              internal
              invoiceNumber
              month
              purchaseOrder
              tax
              total
              type
              usage
              vendor
              year
            }
            error {
              code
              message
              statusCode
            }
          }
        }
      `

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            invoice
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default MonthCloseRepo
