import React, { useCallback, useEffect, useState } from 'react'
import saveUserAccessUseCase from '../use-cases/save-user-access-use-case'
import UserAllocationSelection from './UserAllocationSelection'
import getUserLevel from '../helpers/getUserLevel'
import { Button, Col, Form, Row, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import LoadingSpinner from 'components/common/LoadingSpinner'
import { toast } from 'react-toastify'

const UserAllocation = ({ repoFactory, selectedUser, isNew, sorElements, allocationYear, getUserElements }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [user, setUser] = useState(selectedUser)
  const [reference, setReference] = useState('')
  const [selectedElementLevel, setSelectedElementLevel] = useState(getUserLevel(selectedUser))
  const [selectedElements, setSelectedElements] = useState([{ index: 0 }])
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const addMoreGrants = () => {
    const newArray = selectedElements.slice()
    const lastIndex = Math.max.apply(
      Math,
      newArray.map((o) => o.index)
    )
    newArray.push({
      index: lastIndex + 1
    })
    setSelectedElements(newArray)
  }

  const removeGrants = (selectedItem) => {
    const filtered = selectedElements.filter((el) => el.index !== selectedItem.index)
    setSelectedElements(filtered)
  }

  useEffect(() => {
    if (user && selectedUser) {
      if (selectedUser.Username !== user.Username) {
        setUser(selectedUser)
        setSelectedElementLevel(getUserLevel(selectedUser))
      }
    } else {
      setUser(selectedUser)
      setSelectedElementLevel(getUserLevel(selectedUser))
    }
  }, [selectedUser, user, setUser, setSelectedElementLevel])

  useEffect(() => {
    if (isNew) {
      setSelectedElements([{ index: 0 }])
    } else {
      const latestAccess = user.mappings.find((item) => item.isCurrent === null || item.isCurrent === true)
      const selectedElements = latestAccess.access.map((element, index) => {
        const obj = {
          index,
          element1: sorElements.elementList1.find((sorElement) => sorElement.id === element.element1Id)
        }

        if (element.element2Id) {
          obj.element2 = sorElements.elementList2.find((sorElement) => sorElement.id === element.element2Id)
        }

        if (element.element3Id) {
          obj.element3 = sorElements.elementList3.find((sorElement) => sorElement.id === element.element3Id)
        }

        if (element.element4Id) {
          obj.element4 = sorElements.elementList4.find((sorElement) => sorElement.id === element.element4Id)
        }

        return obj
      })

      setSelectedElements(selectedElements)
    }
  }, [user, isNew, sorElements, setSelectedElements])

  useEffect(() => {
    let shouldDisable = true
    if (selectedElementLevel && selectedElements) {
      const isValidArray = selectedElements.every((el) => el.hasOwnProperty(`element${selectedElementLevel}`))
      shouldDisable = !isValidArray
    }

    setIsSubmitDisabled(shouldDisable)
  }, [selectedElementLevel, selectedElements, setIsSubmitDisabled])

  const handleElementSelect = useCallback(
    (selectedAllocation) => {
      const newArray = selectedElements.slice()
      const selectedElementIndex = newArray.findIndex((el) => el.index === selectedAllocation.index)
      newArray[selectedElementIndex] = selectedAllocation

      setSelectedElements(newArray)
    },
    [setSelectedElements, selectedElements]
  )

  const handleSubmit = (user) => {
    setIsLoading(true)
    const elements = selectedElements.map((element) => {
      const { element1, element2, element3, element4 } = element
      const obj = {
        element1: element1.id
      }
      if (element2) {
        obj.element2 = element2.id
      }
      if (element3) {
        obj.element3 = element3.id
      }
      if (element4) {
        obj.element4 = element4.id
      }
      return obj
    })

    saveUserAccessUseCase(
      {
        username: user.Username,
        allocationYear,
        reference,
        startDate: `01-01-${allocationYear}`,
        access: elements
      },
      {
        userMapperRepo: repoFactory.userMapperRepo(),
        observer: {
          errorWhileSavingUserAccess: () => {
            setIsLoading(false)
          },
          saveUserAccessSuccessful: () => {
            getUserElements()
            setIsLoading(false)
            toast.success(isNew ? 'Mapping Successful' : 'Update Successful', {
              hideProgressBar: true
            })
          }
        }
      }
    )
  }

  return (
    <Card>
      <Card.Body>
        {isLoading && <LoadingSpinner />}
        {selectedElementLevel && selectedElementLevel > 0 && (
          <>
            <Row>
              <Form.Group as={Row} className="mb-3" controlId="formBasicReference">
                <Form.Label column xs="2">
                  Reference
                </Form.Label>
                <Col xs="10">
                  <Form.Control
                    type="text"
                    value={reference}
                    onChange={(e) => {
                      setReference(e.target.value)
                    }}
                  />
                </Col>
              </Form.Group>
            </Row>
            <div className="user-access-table">
              <Row className="user-access-table-header">
                <Col>
                  <FormattedMessage id="sor.element1Name" defaultMessage="Business Unit" />
                </Col>
                <Col>
                  <FormattedMessage id="sor.element2Name" defaultMessage="Department" />
                </Col>
                <Col>
                  <FormattedMessage id="sor.element3Name" defaultMessage="Portfolio Name" />
                </Col>
                <Col>
                  <FormattedMessage id="sor.element4Name" defaultMessage="Product Name" />
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="user-access-table-body">
                {selectedElements.map((selectedElement, index) => {
                  return (
                    <UserAllocationSelection
                      key={selectedElement.index}
                      updateAllocation={handleElementSelect}
                      sorElements={sorElements}
                      allocation={selectedElement}
                      selectedElementLevel={selectedElementLevel}
                      removeGrants={removeGrants}
                      index={index}
                      selectedElementsLength={selectedElements.length}
                      addMoreGrants={addMoreGrants}
                    />
                  )
                })}
              </Row>
            </div>

            <Row>
              <Col xs={4} />
              <Col xs={4}>
                <Button variant="primary" onClick={() => handleSubmit(user)} disabled={isSubmitDisabled}>
                  {isNew ? 'Grant Access' : 'Re-Grant Access'}
                </Button>
              </Col>
              <Col xs={4} />
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default UserAllocation
