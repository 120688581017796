const ERROR_MESSAGES = {
  REQUIRED: 'This field is required.',
  INVALID_EMAIL: 'Please enter a valid email address.',
  GENERIC_ERROR: 'An error occurred. Please try again later.',
  SELECT_OPTION: 'Please select at least one option.',
  INVALID_UUID: 'Please enter a valid UUID.',
  NUMERIC: 'Please enter only numbers.'
}

export default ERROR_MESSAGES
