const getUserMappingAndDashboardList = ({ token, year }, { observer, biRepo }) => {
  biRepo.getUserMappingAndDashboardList(
    { token, year },
    {
      success: (response) => {
        if (response.getUserMappingToBudgetsAndAccounts.error || response.getDashboardList.error) {
          observer.errorReceivingData()
          return
        }

        observer.receiveData({
          qsDashboardObj: response.getDashboardList.data,
          elementAccountList: response.getUserMappingToBudgetsAndAccounts.data
        })
      },
      failure: () => {
        observer.errorReceivingData()
      }
    }
  )
}

export default getUserMappingAndDashboardList
