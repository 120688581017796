import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'

import ElementList from './ElementList'
import moment from 'moment'

const AllocationItem = ({
  sorElements,
  removeAllocation,
  updateAllocation,
  allocation,
  addNewAllocation,
  allocationsLength,
  index,
  totalAllocation,
  isNew,
  selectedType,
  setSelectedType,
  effectiveDate,
  setEffectiveDate,
  minDate
}) => {
  const [validDates, setValidDates] = useState([])
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false)
  const element1List = sorElements.elementList1
  const element2List = allocation.element1
    ? sorElements.elementList2.filter((el) => el.element1Id === allocation.element1.id)
    : []
  const element3List = allocation.element2
    ? sorElements.elementList3.filter((el) => el.element2Id === allocation.element2.id)
    : []
  const element4List = allocation.element3
    ? sorElements.elementList4.filter((el) => el.element3Id === allocation.element3.id)
    : []

  const onElementChange = (eventKey) => {
    const updatedAllocation = allocation
    const e = JSON.parse(eventKey)
    updatedAllocation[e.type] = e
    if (e.type === 'element1') {
      updatedAllocation.element2 && delete updatedAllocation.element2
      updatedAllocation.element3 && delete updatedAllocation.element3
      updatedAllocation.element4 && delete updatedAllocation.element4
    }
    if (e.type === 'element2') {
      updatedAllocation.element3 && delete updatedAllocation.element3
      updatedAllocation.element4 && delete updatedAllocation.element4
    }

    if (e.type === 'element3') {
      updatedAllocation.element4 && delete updatedAllocation.element4
    }
    updateAllocation(updatedAllocation)
  }

  const onAllocationChange = (e) => {
    const updatedAllocation = allocation
    updatedAllocation.percentage = e.target.value
    updateAllocation(updatedAllocation)
  }

  useEffect(() => {
    const now = new Date()
    const startDateRange = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const endDateRange = new Date(now.getFullYear(), 11, 1)

    const dates = []
    let date = new Date(startDateRange)

    while (date <= endDateRange) {
      dates.push(new Date(date))
      date.setMonth(date.getMonth() + 1)
    }

    setValidDates(dates)
  }, [effectiveDate])

  return (
    <tr>
      <td>
        {allocation.index === 0 && (
          <>
            <DropdownButton
              disabled={!isNew}
              id="environment-select"
              title={selectedType}
              onSelect={(e) => {
                setSelectedType(e)
              }}
            >
              <Dropdown.Item eventKey="Sandbox">Sandbox</Dropdown.Item>
              <Dropdown.Item eventKey="Dev">Dev</Dropdown.Item>
              <Dropdown.Item eventKey="Shared">Shared</Dropdown.Item>
              <Dropdown.Item eventKey="Pre-Prod">Pre-Prod</Dropdown.Item>
              <Dropdown.Item eventKey="Prod">Prod</Dropdown.Item>
            </DropdownButton>
          </>
        )}
      </td>
      {!isNew && (
        <td>
          {allocation.index === 0 && (
            <>
              <button
                className="btn btn-primary btn-sm btn-date"
                onClick={() => setIsDatepickerOpen(!isDatepickerOpen)}
              >
                {moment(effectiveDate).format('MM-DD-YYYY')}
              </button>
              {isDatepickerOpen && (
                <DatePicker
                  includeDates={validDates}
                  minDate={validDates[0]}
                  selected={effectiveDate}
                  onChange={(date) => {
                    setEffectiveDate(date)
                    setIsDatepickerOpen(false)
                  }}
                  onClickOutside={() => setIsDatepickerOpen(false)}
                  inline
                />
              )}
            </>
          )}
        </td>
      )}
      <td>
        <ElementList
          id={`element1ForIndex${allocation.index}`}
          elementType="element1"
          elements={element1List}
          onChange={onElementChange}
          dropDownLabel={(allocation.element1 && allocation.element1.value) || 'please select'}
        />
      </td>
      <td>
        <ElementList
          id={`element2ForIndex${allocation.index}`}
          elementType="element2"
          elements={element2List}
          onChange={onElementChange}
          dropDownLabel={(allocation.element2 && allocation.element2.value) || 'please select'}
        />
      </td>
      <td>
        <ElementList
          id={`element3ForIndex${allocation.index}`}
          elementType="element3"
          elements={element3List}
          onChange={onElementChange}
          dropDownLabel={(allocation.element3 && allocation.element3.value) || 'please select'}
        />
      </td>
      <td>
        <ElementList
          id={`element4ForIndex${allocation.index}`}
          elementType="element4"
          elements={element4List}
          onChange={onElementChange}
          dropDownLabel={(allocation.element4 && allocation.element4.value) || 'please select'}
        />
      </td>
      <td>
        <input
          type="number"
          name="percentage"
          min="1"
          max="100"
          value={allocation.percentage || 1}
          onChange={onAllocationChange}
        />
      </td>
      <td>
        {allocation.index !== 0 && (
          <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" onClick={() => removeAllocation(allocation)} />
        )}
        {index === allocationsLength - 1 && totalAllocation < 100 && (
          <FontAwesomeIcon icon={faPlusCircle} className="add-more-icon" onClick={addNewAllocation} />
        )}
      </td>
    </tr>
  )
}

AllocationItem.propTypes = {}

export default AllocationItem
