import React, { useContext } from 'react'

import './footer.scss'
import finOpsCenterlogo from '../../assets/logo.svg'
import awsLogo from '../../assets/aws-logo.png'
import awsLogoWhite from '../../assets/aws-logo-white.png'
import AppContext from 'context/AppContext'

const Footer = () => {
  const { theme } = useContext(AppContext)
  return (
    <footer>
      <div className="mb-1">
        <img src={finOpsCenterlogo} alt="FinOps Center Logo" height="40" />
        <span className="divider mx-3"></span>
        <span className="aws-logo">
          {theme === 'dark' ? (
            <img src={awsLogoWhite} alt="Powered by AWS" height="40" />
          ) : (
            <img src={awsLogo} alt="Powered by AWS" height="40" />
          )}
        </span>
      </div>
      <p className="copyright mb-0">
        FinOps Center is a registered trademark of Cloud Scal3 Inc, and all rights are reserved.
      </p>
    </footer>
  )
}

export default Footer
