import { Card } from 'react-bootstrap'
import PortfolioCard from './PortfolioCard'
import Slider from 'react-slick'
import useElementNames from 'hooks/useElementNames'
import pluralize from 'pluralize'
const slidesToShow = 3

const Portfolios = ({ department, isMonthly, setSelectedPortfolio, selectedPortfolio }) => {
  const portfolios = department.portfolios || []
  const elementNames = useElementNames()

  const settings = {
    ...(portfolios.length < slidesToShow && { className: 'slick-align-left' }),
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1
  }
  return (
    <Card className="departments section-gray mb-3">
      <Card.Body>
        <h3>{pluralize(elementNames.element3)}</h3>
        {portfolios.length > 0 ? (
          <Slider {...settings}>
            {portfolios.map((portfolio, i) => (
              <PortfolioCard
                key={`portfolio-card-${i}`}
                departmentName={department.name}
                portfolio={portfolio}
                isMonthly={isMonthly}
                setSelectedPortfolio={setSelectedPortfolio}
                selectedPortfolio={selectedPortfolio}
              />
            ))}
          </Slider>
        ) : (
          <div className="alert alert-info">There is not any portfolio for this department</div>
        )}
      </Card.Body>
    </Card>
  )
}
export default Portfolios
