import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { getDateRange } from 'Summary/helpers/getDateRange'
import { Status } from 'Summary/constants/TimeCardStatus'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const columnHelper = createColumnHelper()

const CardDetails = ({ items, type, inputMonth }) => {
  const [data, setData] = useState([])
  const [sorting, setSorting] = useState([])

  const columns = [
    columnHelper.group({
      id: 'header',
      header: () => <div className={`badge ${type}`}>{type}</div>,

      columns: [
        columnHelper.accessor('product', {
          header: () => 'Product',
          cell: (info) => {
            const { productOwners, portfolioOwners } = info.row.original.rawRowData
            return (
              <>
                {productOwners || portfolioOwners ? (
                  <>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          <div className="text-start">
                            <div className="fw-bold">Product Owner</div>
                            <div className="fw-light mb-1">{productOwners.join(', ')}</div>

                            <div className="fw-bold">Portfolio Owner</div>
                            <div className="fw-light">{portfolioOwners.join(', ')}</div>
                          </div>
                        </Tooltip>
                      }
                    >
                      <button>{info.getValue()}</button>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>{info.getValue()}</>
                )}
              </>
            )
          }
        }),
        columnHelper.accessor('spendPeriod', {
          header: () => 'Spend Period'
        }),
        columnHelper.accessor('spend', {
          header: () => 'Spend',
          cell: (props) => (
            <div
              style={{
                color: getConditionalSpendColor(
                  props.row.original.rawRowData.budgets?.[`_${inputMonth - 1}`],
                  props.row.original.rawRowData.total,
                  props.row.original.rawRowData.numOfDays
                )
              }}
            >
              {props.row.original.spend}
            </div>
          )
        })
      ]
    })
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  useEffect(() => {
    setData(
      items.map((item) => {
        return {
          product: item.element4,
          spendPeriod: getDateRange(item.week, inputMonth, item.year),
          status: Status[item.status],
          spend: dollarPriceFormatter(item.total),
          rawRowData: item
        }
      })
    )
  }, [inputMonth, items])

  const getConditionalSpendColor = (monthlyPlannedBudget, timeCardSpent, numOfDays) => {
    if (!monthlyPlannedBudget || !timeCardSpent || !numOfDays) return 'black'

    const dailyBadget = monthlyPlannedBudget / 30

    const percentage = ((dailyBadget * numOfDays) / timeCardSpent) * 100

    if (percentage > 100) {
      return 'green'
    } else if (percentage === 100) {
      return 'black'
    } else if (percentage > 90) {
      return 'orange'
    }

    return 'red'
  }

  return (
    <div className="table-responsive" style={{ maxHeight: '300px' }}>
      <table className="table table-hover">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan} className="text-nowrap">
                  {header.isPlaceholder ? null : (
                    <button
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-between align-items-center user-select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      className="sorting-button"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                        desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                      }[header.column.getIsSorted()] ?? null}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="text-nowrap">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CardDetails
