import React, { useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import getWeeklyAllocationReportColumns from './WeeklyAllocationReportColumns'

import './WeeklyAllocationReport.scss'
import useElementNames from 'hooks/useElementNames'

const WeeklyAllocationReport = ({ actualsData, accountsData = [] }) => {
  const elementNames = useElementNames()
  const [data, setData] = useState([])
  const [currentTimeRanges, setCurrentTimeRanges] = useState([])
  const [previousTimeRanges, setPreviousTimeRanges] = useState([])
  const [grouping] = React.useState(['element4', 'accountId'])
  const [columnPinning] = React.useState({
    left: ['element4', 'accountId', 'percentage']
  })

  const table = useReactTable({
    data,
    columns: getWeeklyAllocationReportColumns(currentTimeRanges, previousTimeRanges, accountsData, elementNames),
    state: {
      grouping,
      columnPinning
    },
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    setData(actualsData.usages)
    setCurrentTimeRanges(actualsData.currentTimeRanges)
    setPreviousTimeRanges(actualsData.previousTimeRanges)
  }, [actualsData])

  return (
    <div className="table-responsive d-flex">
      <table className="table table-fixed table-bordered table-hover table-striped">
        <thead>
          {table.getLeftHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan} className="text-nowrap">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getLeftVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {cell.getIsGrouped() ? (
                      <>
                        <button
                          {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: {
                              cursor: row.getCanExpand() ? 'pointer' : 'normal'
                            }
                          }}
                        >
                          {row.getIsExpanded() ? (
                            <FontAwesomeIcon icon={faChevronDown} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronRight} />
                          )}{' '}
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}{' '}
                          <span className="group-quantity">({row.subRows.length})</span>
                        </button>
                      </>
                    ) : cell.getIsAggregated() ? (
                      flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                    ) : cell.getIsPlaceholder() ? null : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>

      <table className="table table-bordered  table-hover table-striped">
        <thead>
          {table.getCenterHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={header.id === 'Current' || header.column.parent?.id === 'Current' ? 'current-month' : ''}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getCenterVisibleCells().map((cell) => (
                  <td key={cell.id} className={cell.column.parent.id === 'Current' ? 'current-month' : ''}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default WeeklyAllocationReport
