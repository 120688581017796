const getBudgetsMap = ({ year, token, userRole }, { observer, budgetsRepo }) => {
  budgetsRepo.getAllBudgetsNew(
    { year, token },
    {
      success: (budgetsResponse) => {
        if (budgetsResponse.error) {
          observer.errorReceivingActuals()
          return
        }

        observer.receiveBudgets(budgetsResponse)
      },
      failure: () => {
        observer.errorReceivingBudgets()
      }
    }
  )
}

export default getBudgetsMap
