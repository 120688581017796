import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Alert, Form, Card } from 'react-bootstrap'
import AllocationItems from './AllocationItems'
import saveAllocations from '../use-cases/save-allocations'
import LoadingSpinner from 'components/common/LoadingSpinner'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

const Allocation = ({
  repoFactory,
  selectedAccount,
  isNew,
  sorElements,
  selectedAccountForRemap,
  onSuccessfulAllocationChange,
  allocationYear,
  getAccounts,
  sorElementMapping
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [account, setAccount] = useState(selectedAccount)
  const [reference, setReference] = useState('')
  const [allocations, setAllocations] = useState(null)
  const [totalAllocation, setTotalAllocation] = useState(0)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [selectedAccountType, setSelectedAccountType] = useState(null)
  const [effectiveDate, setEffectiveDate] = useState(new Date(`01-01-${allocationYear}`))
  const [minDate, setMinDate] = useState(new Date(`01-01-${allocationYear}`))
  const [error, setError] = useState(false)

  useEffect(() => {
    if (account && selectedAccount) {
      if (selectedAccount.accountId !== account.accountId) {
        setAccount(selectedAccount)
      }
    } else {
      setAccount(selectedAccount)
    }
  }, [selectedAccount, account])

  useEffect(() => {
    if (isNew) {
      setAllocations(null)
      setReference('')
      setSelectedAccountType(null)
      if (selectedAccount.createdDate) {
        const selectedAccountsCreationDate = moment.utc(selectedAccount.createdDate).local()

        if (selectedAccountsCreationDate.year() === allocationYear) {
          setEffectiveDate(new Date(selectedAccountsCreationDate.format('MM-DD-YYYY')))
        }
      }
    }
  }, [isNew, setAllocations, setReference, setSelectedAccountType])

  useEffect(() => {
    let disabled = true
    if (allocations) {
      const total = allocations.reduce((total, currentVal) => total + Number(currentVal.percentage || 0), 0)
      const hasValidTotals = total === 100

      const objectElements = ['element1', 'element2', 'element3', 'element4']
      const hasAllKeys = allocations.every((obj) => objectElements.every((item) => obj.hasOwnProperty(item)))

      disabled = !(hasValidTotals && hasAllKeys)
    }

    disabled = disabled || !selectedAccountType

    if (!isNew) {
      disabled = disabled || !effectiveDate
    }

    setIsSubmitDisabled(disabled)
  }, [allocations, selectedAccountType, isNew, effectiveDate, setIsSubmitDisabled])

  useEffect(() => {
    if (selectedAccountForRemap) {
      const currentAllocation = selectedAccountForRemap.mappings.find(
        (item) => item.endDate === `12-31-${allocationYear}`
      )

      setMinDate(new Date(currentAllocation.startDate))
      setReference('')
      setEffectiveDate(moment(currentAllocation.startDate, 'MM-DD-YYYY').toDate())
      setSelectedAccountType(currentAllocation.accountType)
      setTotalAllocation(100)
    }
  }, [selectedAccountForRemap, allocationYear, setReference])

  const saveSelectedAllocations = () => {
    setIsLoading(true)
    saveAllocations(
      {
        account,
        accountType: selectedAccountType,
        name: selectedAccount.accountName,
        reference: reference !== '' ? reference : null,
        allocationYear,
        effectiveDate,
        allocations
      },
      {
        accountMapperRepoNew: repoFactory.accountMapperRepoNew(),
        observer: {
          allocationsSaved: () => {
            onSuccessfulAllocationChange()
            getAccounts()
            setError(false)
            setIsLoading(false)
            toast.success(isNew ? 'Mapping Successful' : 'Update Successful', {
              hideProgressBar: true
            })
          },
          allocationsFailed: () => {
            setError(true)
            setIsLoading(false)
          }
        }
      }
    )
  }

  return (
    <Card>
      <Card.Body>
        {error && <Alert variant={'danger'}>Please validate date</Alert>}
        {isLoading && <LoadingSpinner />}
        <Row>
          <Col xs="6">
            <Form.Group as={Row} className="mb-3" controlId="formBasicName">
              <Form.Label column xs="3">
                Name
              </Form.Label>
              <Col xs="9">
                <Form.Control type="text" value={selectedAccount.accountName} disabled={true} />
              </Col>
            </Form.Group>
          </Col>
          <Col xs="6">
            <Form.Group as={Row} className="mb-3" controlId="formBasicReference">
              <Form.Label column xs="3">
                Reference
              </Form.Label>
              <Col xs="9">
                <Form.Control
                  type="text"
                  value={reference}
                  onChange={(e) => {
                    setReference(e.target.value)
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <AllocationItems
          repoFactory={repoFactory}
          selectedAccount={account}
          sorElements={sorElements}
          sorElementMapping={sorElementMapping}
          totalAllocation={totalAllocation}
          setTotalAllocation={setTotalAllocation}
          onChange={setAllocations}
          isNew={isNew}
          selectedType={selectedAccountType}
          setSelectedType={setSelectedAccountType}
          effectiveDate={effectiveDate}
          setEffectiveDate={setEffectiveDate}
          minDate={minDate}
          selectedAccountForRemap={selectedAccountForRemap}
          allocationYear={allocationYear}
        />

        <div className="text-center">
          <Button onClick={saveSelectedAllocations} disabled={isSubmitDisabled}>
            {isNew ? 'Map Account' : 'Re-Map Account'}
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}

Allocation.propTypes = {}

export default Allocation
