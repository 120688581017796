import React, { useCallback, useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/AppContext'
import getAllUsersUseCase from '../use-cases/get-all-users-use-case'
import InviteUser from './InviteUser'
import ListUsers from './ListUsers'
import { Alert } from 'react-bootstrap'
import LoadingSpinner from 'components/common/LoadingSpinner'

const User = (props) => {
  const { repoFactory } = useContext(AppContext)

  const [error, setError] = useState(false)
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getAllUsers = useCallback(() => {
    setIsLoading(true)
    getAllUsersUseCase({
      userRepo: repoFactory.userRepo(),
      observer: {
        receiveUsersInfo: (response) => {
          setUsers(response)
          setError(false)
          setIsLoading(false)
        },
        errorWhileReceivingUsers: () => {
          setError(true)
          setIsLoading(false)
        }
      }
    })
  }, [repoFactory, setUsers, setError])

  useEffect(() => {
    getAllUsers()
  }, [repoFactory, setUsers, setError, getAllUsers])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {error && <Alert variant={'danger'}>Error retrieving users, please check and try again</Alert>}
      <InviteUser repoFactory={repoFactory} getAllUsers={getAllUsers} {...props} />
      <ListUsers repoFactory={repoFactory} users={users} {...props} getAllUsers={getAllUsers} />
    </>
  )
}

export default User
