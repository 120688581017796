import React from 'react'
import { Card, Dropdown, Nav, Navbar } from 'react-bootstrap'
import private_routes from '../../config/private_routes'
import { getAllowedRoutes } from '../../utils'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import CustomToggle from '../Toggle/CustomToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import AppContext from '../../context/AppContext'
import Routes from '../../routes'
import './MenuBar.scss'

const MenuBar = (props) => {
  const { selectedYear, setSelectedYear, availableYears } = React.useContext(AppContext)

  const allowedRoutes = getAllowedRoutes(private_routes)
  const routes = allowedRoutes.map((route) => {
    return (
      <Nav.Item key={route.path}>
        <Link to={route.path}>
          <Card className={`menu-card${route.activePaths.includes(props.location.pathname) ? ' active' : ''}`}>
            <Card.Body>
              <Card.Text>{route.title}</Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Nav.Item>
    )
  })

  const showYearSelector = ![Routes.DASHBOARD.PATH].includes(window.location.pathname)

  return (
    <Navbar activeKey={props.location.pathname} className="menu-bar">
      <div className="menu-title">
        <FormattedMessage id="menu.title" defaultMessage="Cloud" />
        {showYearSelector && (
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} icon={<FontAwesomeIcon icon={faCalendarAlt} />} id="menu-year-selection">
              {selectedYear}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {availableYears.map((mapYear) => {
                return (
                  <Dropdown.Item
                    key={mapYear}
                    active={mapYear === selectedYear}
                    eventKey={mapYear}
                    onClick={() => {
                      setSelectedYear(mapYear)
                    }}
                  >
                    {mapYear}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="menu-items">{routes}</div>
    </Navbar>
  )
}

export default MenuBar
