import React, { useEffect, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import getUsageTreeColumns from './UsageTreeColumns'
import { isCurrentMonth, isFutureMonth } from 'utils/reports-helper'
import UserLevelMap from 'utils/user-level-map'

import './UsageTree.scss'
import useElementNames from 'hooks/useElementNames'

const UsageTree = ({ actualsData, year, accountsData }) => {
  const elementNames = useElementNames()
  const [data, setData] = useState([...actualsData])
  const [accountNameData, setAccountNameData] = useState(accountsData)
  const [selectedYear, setSelectedYear] = useState(year)
  const userRole = JSON.parse(localStorage.getItem('roles'))
  const userAccess = UserLevelMap[userRole]

  const columns = [
    userAccess === 1 ? 'element1' : undefined,
    userAccess <= 2 ? 'element2' : undefined,
    userAccess <= 3 ? 'element3' : undefined,
    'element4',
    'accountId',
    'percentage'
  ].filter(Boolean)

  const [grouping] = useState([...columns])
  const [columnPinning] = useState({
    left: [...columns]
  })

  const table = useReactTable({
    data,
    columns: getUsageTreeColumns(selectedYear, columns, elementNames),
    state: {
      grouping,
      columnPinning
    },
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    setData([...actualsData])
    setAccountNameData(accountsData)
    setSelectedYear(year)
  }, [actualsData, year])

  const getAccountName = (accountId) => accountNameData[accountId]?.accountName

  return (
    <>
      <div className="table-responsive d-flex">
        <table className="react-table table table-fixed table-bordered table-hover table-striped">
          <thead>
            {table.getLeftHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan} className="text-nowrap">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getLeftVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {cell.getIsGrouped() ? (
                      <>
                        {row.groupingColumnId === 'percentage' ? (
                          <>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={(props) => (
                                <Tooltip id="button-tooltip" {...props}>
                                  {`${row.original.startDate} to ${row.original.endDate}`}
                                </Tooltip>
                              )}
                            >
                              <Button variant="link">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            {row.groupingColumnId === 'accountId' ? (
                              <>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                      {getAccountName(cell.getValue())}
                                    </Tooltip>
                                  )}
                                >
                                  <button
                                    {...{
                                      onClick: row.getToggleExpandedHandler(),
                                      style: {
                                        cursor: row.getCanExpand() ? 'pointer' : 'normal'
                                      }
                                    }}
                                  >
                                    {row.getIsExpanded() ? (
                                      <FontAwesomeIcon icon={faChevronDown} />
                                    ) : (
                                      <FontAwesomeIcon icon={faChevronRight} />
                                    )}{' '}
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}{' '}
                                    <span className="group-quantity">({row.subRows.length})</span>
                                  </button>
                                </OverlayTrigger>
                              </>
                            ) : (
                              <button
                                {...{
                                  onClick: row.getToggleExpandedHandler(),
                                  style: {
                                    cursor: row.getCanExpand() ? 'pointer' : 'normal'
                                  }
                                }}
                              >
                                {row.getIsExpanded() ? (
                                  <FontAwesomeIcon icon={faChevronDown} />
                                ) : (
                                  <FontAwesomeIcon icon={faChevronRight} />
                                )}{' '}
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}{' '}
                                <span className="group-quantity">({row.subRows.length})</span>
                              </button>
                            )}
                          </>
                        )}
                      </>
                    ) : cell.getIsAggregated() ? (
                      flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                    ) : cell.getIsPlaceholder() ? null : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <table className="react-table table table-bordered  table-hover table-striped">
          <thead>
            {table.getCenterHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      isCurrentMonth(selectedYear, header.id)
                        ? 'current-month'
                        : isFutureMonth(selectedYear, header.id)
                        ? 'future-month'
                        : ''
                    }
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getCenterVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={
                      isCurrentMonth(selectedYear, cell.column.id)
                        ? 'current-month'
                        : isFutureMonth(selectedYear, cell.column.id)
                        ? 'future-month'
                        : ''
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default UsageTree
