import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import submitBudget from '../use-cases/submitBudget'
import getButtonStatus from '../helpers/getButtonStatus'
import isInputDisabled from '../helpers/isInputDisabled'
import postReview from '../use-cases/postReview'
import Roles from '../../config/Roles'
import { BudgetStatus } from '../constants/BudgetStatus'
import BudgetInputField from './BudgetInputField'
import priceParser from '../../formatters/price_parser'
import LoadingSpinner from 'components/common/LoadingSpinner'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'

const months = [
  [
    {
      name: 'January',
      value: '_0'
    },
    {
      name: 'February',
      value: '_1'
    },
    {
      name: 'March',
      value: '_2'
    }
  ],
  [
    {
      name: 'April',
      value: '_3'
    },
    {
      name: 'May',
      value: '_4'
    },
    {
      name: 'June',
      value: '_5'
    }
  ],
  [
    {
      name: 'July',
      value: '_6'
    },
    {
      name: 'August',
      value: '_7'
    },
    {
      name: 'September',
      value: '_8'
    }
  ],
  [
    {
      name: 'October',
      value: '_9'
    },
    {
      name: 'November',
      value: '_10'
    },
    {
      name: 'December',
      value: '_11'
    }
  ]
]

const BudgetEntry = ({ selectedBudget, getBudgets }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [budgetInfo, setBudgetInfo] = useState({
    annual: 0,
    planned: 0,
    available: 0
  })
  const roles = JSON.parse(localStorage.getItem('roles'))
  const isProductManager = roles.includes(Roles.PRODUCT_MANAGERS)
  const isPortfolioManager = roles.includes(Roles.PORTFOLIO_MANAGERS)

  const { selectedYear, repoFactory } = useContext(AppContext)
  const [monthsData, setMonthsData] = useState(selectedBudget.budgets)
  const [estimatedBudgetsData, setEstimatedBudgetsData] = useState(selectedBudget.estimatedBudgets)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [randomKey, setRandomKey] = useState(Math.random())

  useEffect(() => {
    setMonthsData(selectedBudget.budgets)
    setEstimatedBudgetsData(selectedBudget.estimatedBudgets)
    setRandomKey(Math.random())
    setIsSubmitting(false)
  }, [selectedBudget])

  useEffect(() => {
    const annual = selectedBudget.amount
    const planned = getPlannedBudget()

    setBudgetInfo({
      ...budgetInfo,
      annual,
      planned,
      available: annual - planned
    })
  }, [selectedBudget, monthsData])

  const submitForm = () => {
    setIsSubmitting(true)
    setIsLoading(true)
    if (roles.includes(Roles.PRODUCT_MANAGERS)) {
      const budgets = { ...monthsData }
      const isReschedule = BudgetStatus.APPROVED === selectedBudget.status
      submitBudget(
        {
          token: localStorage.getItem('authToken'),
          year: selectedYear,
          budgets,
          element1: selectedBudget.element1,
          element2: selectedBudget.element2,
          element3: selectedBudget.element3,
          element4: selectedBudget.element4,
          executionId: selectedBudget.executionId,
          isReschedule
        },
        {
          budgetsRepo: repoFactory.budgetsRepo(),
          observer: {
            errorWhileCreatingBudget: () => {
              setIsSubmitting(false)
              setIsLoading(false)
            },
            successfullyCreatedBudget: () => {
              setIsSubmitting(true)
              setIsLoading(false)
              getBudgets()
            }
          }
        }
      )
    }
  }

  const submitReview = (status) => {
    setIsLoading(true)
    postReview(
      {
        token: localStorage.getItem('authToken'),
        executionId: selectedBudget.executionId,
        status
      },
      {
        budgetsRepo: repoFactory.budgetsRepo(),
        observer: {
          errorWhileSubmittingReview: () => {
            setIsLoading(false)
          },
          reviewUpdated: () => {
            getBudgets()
            setIsLoading(false)
          }
        }
      }
    )
  }

  const onInputChange = (e) => {
    const newData = { ...monthsData }
    newData[e.target.name] = Number(priceParser(e.target.value))
    setMonthsData(newData)
  }

  const getPlannedBudget = () => {
    const plannedBudget = Object.values(monthsData).reduce((a, c = 0) => a + c, 0)
    return plannedBudget
  }

  return (
    <div className="budget-entry mb-5">
      {isLoading && <LoadingSpinner />}
      <Form>
        <Card className="section-gray mb-3">
          <Card.Body>
            <Row>
              <Col>
                <h4>
                  <span>Annual Budget </span>
                  {dollarPriceFormatter(budgetInfo.annual)}
                </h4>
              </Col>
              <Col>
                <h4>
                  <span>Planned Budget</span> {dollarPriceFormatter(budgetInfo.planned)}
                </h4>
              </Col>
              <Col>
                <h4>
                  <span>Unallocated Budget</span> {dollarPriceFormatter(budgetInfo.available)}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h5 className="badge bg-primary" style={{ marginBottom: '-10px' }}>
                  Space Estimate
                </h5>
              </Col>
            </Row>

            <FormInputs
              isInputDisabled={isSubmitting || isInputDisabled(selectedBudget, selectedYear)}
              onChange={onInputChange}
              selectedYear={selectedYear}
              data={monthsData}
              estimatedBudgetsData={estimatedBudgetsData}
              inputKey={`${selectedBudget.element1}-${selectedBudget.element2}-${selectedBudget.element3}-${selectedBudget.element4}-${randomKey}`}
            />
          </Card.Body>
        </Card>

        <Row>
          {isProductManager && (
            <Button
              variant="primary"
              type="button"
              onClick={submitForm}
              disabled={isSubmitting || getButtonStatus(selectedBudget, monthsData, selectedYear)}
            >
              {BudgetStatus.APPROVED === selectedBudget.status ? 'Reschedule' : 'Submit'}
            </Button>
          )}
          {isPortfolioManager && (
            <>
              <Col>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    submitReview(
                      selectedBudget.status === BudgetStatus.REQUIRE_RESCHEDULE
                        ? BudgetStatus.PENDING_UPDATE
                        : BudgetStatus.APPROVED
                    )
                  }}
                  disabled={isSubmitting || getButtonStatus(selectedBudget, monthsData, selectedYear)}
                >
                  {selectedBudget.status === BudgetStatus.REQUIRE_RESCHEDULE ? 'Approve Reschedule' : 'Approve'}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => {
                    submitReview(
                      selectedBudget.status === BudgetStatus.REQUIRE_RESCHEDULE
                        ? BudgetStatus.RESCHEDULE_REJECTED
                        : BudgetStatus.REJECTED
                    )
                  }}
                  disabled={isSubmitting || getButtonStatus(selectedBudget, monthsData, selectedYear)}
                >
                  {selectedBudget.status === BudgetStatus.REQUIRE_RESCHEDULE ? 'Deny Reschedule' : 'Deny'}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default BudgetEntry

const FormInputs = ({ isInputDisabled, onChange, data, selectedYear, inputKey, estimatedBudgetsData }) => {
  return months.map((monthArray, index) => (
    <Row key={index}>
      {monthArray.map((month) => {
        return (
          <BudgetInputField
            key={`${inputKey}-${month.name}`}
            month={month}
            selectedYear={selectedYear}
            onChange={onChange}
            isInputDisabled={isInputDisabled}
            data={data}
            estimatedBudgetsData={estimatedBudgetsData}
          />
        )
      })}
    </Row>
  ))
}
