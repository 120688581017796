const forgotPasswordUseCase = ({ username }, { observer, authRepo }) => {
  authRepo.forgotPassword(
    { username },
    {
      success: (response) => {
        if (!response?.forgotPassword || !response?.forgotPassword?.CodeDeliveryDetails) {
          observer.forgotPasswordFailed()
          return
        }
        observer.forgotPasswordSuccess(response.forgotPassword)
      },
      failure: () => {
        observer.forgotPasswordFailed()
      }
    }
  )
}

export default forgotPasswordUseCase
