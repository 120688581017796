const changeUserRoleUseCase = ({ username, role }, { observer, userRepo }) => {
  userRepo.changeUserRole(
    { username, role },
    {
      success: (data) => {
        if (data.error) {
          observer.errorWhileChangeUserRole()
          return
        }
        observer.successfulChangeUserRole()
      },
      failure: (e) => {
        observer.errorWhileChangeUserRole()
      }
    }
  )
}

export default changeUserRoleUseCase
