import { useContext, useState } from 'react'
import AppContext from 'context/AppContext'
import LoadingSpinner from 'components/common/LoadingSpinner'
import QuickSightEnvironment from './QuickSightEnvironment/QuickSightEnvironment'

import './Configuration.scss'
import BudgetFile from './BudgetFile/BudgetFile'
import { Tab, Tabs } from 'react-bootstrap'

function Configuration() {
  const { repoFactory, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <Tabs defaultActiveKey="budget_file" className="mb-3">
        <Tab eventKey="budget_file" title="Chart / Budget File">
          <BudgetFile repoFactory={repoFactory} setIsLoading={setIsLoading} selectedYear={selectedYear} />
        </Tab>

        <Tab eventKey="quicksight" title="QuickSight Environment">
          <QuickSightEnvironment repoFactory={repoFactory} setIsLoading={setIsLoading} />
        </Tab>
      </Tabs>
    </>
  )
}
export default Configuration
