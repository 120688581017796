import { useState, useEffect, useMemo, useRef } from 'react'
import * as d3 from 'd3'
import { getProductsFromBudgets } from 'Budgets/helpers/functions'
import './TreeMapBudgets.scss'
import { BudgetStatus, BudgetStatusLabel } from 'Budgets/constants/BudgetStatus'
import { Card } from 'react-bootstrap'

const DIMENSIONS = {
  width: 632,
  height: 300
}

const MIN_DISPLAY_PERCENTAGE = 2

const getSpend = (product, isMonthly, month = new Date().getMonth()) => {
  let totalSpend = 0
  if (isMonthly) {
    totalSpend = Number(product.actuals[`_${month}`].cost.toFixed(2))
  } else {
    totalSpend = Number(
      Object.values(product.actuals)
        .reduce((acc, actual) => acc + actual.cost, 0)
        .toFixed(2)
    )
  }
  return totalSpend
}

const getBudget = (product, isMonthly, month = new Date().getMonth()) => {
  // TODO: Check if we can only include approved budgets

  let totalBudget = 0
  if (isMonthly) {
    totalBudget = product.budgets[`_${month}`] ? Number(product.budgets[`_${month}`].toFixed(2)) : 0
  } else {
    totalBudget = Number(
      Object.values(product.budgets)
        .reduce((acc, budget) => acc + (budget || 0), 0)
        .toFixed(2)
    )
  }
  return totalBudget
}

const getBlockColor = (percentage) => {
  if (percentage >= 110) {
    return '#e0252e'
  } else if (percentage >= 102 && percentage < 110) {
    return '#f2b01a'
  } else if (percentage >= 98 && percentage < 102) {
    return '#212529'
  }

  return '#83bb35'
}

const TreeMapBudgets = ({ budgets = [], isMonthly }) => {
  const [tooltip, setTooltip] = useState({ visible: false, content: '' })
  const [data, setData] = useState([])
  const [noSpendData, setNoSpendData] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    if (budgets?.length) {
      const products = getProductsFromBudgets(budgets)

      const children = products.map((product) => {
        const spend = getSpend(product, isMonthly)
        const budget = getBudget(product, isMonthly)
        return {
          type: 'leaf',
          name: product.element4,
          spend,
          budget,
          percentage: budget ? Math.floor((spend / budget) * 100) : 0,
          status: product.status
        }
      })

      const totalSpendChildrens = children.reduce((acc, child) => acc + child.spend, 0)

      setNoSpendData(totalSpendChildrens === 0)

      setData({
        type: 'node',
        name: 'bugdets',
        spend: 0,
        children
      })
    }
  }, [budgets, isMonthly])

  const hierarchy = useMemo(() => {
    return d3.hierarchy(data).sum((d) => d.spend)
  }, [data])

  const root = useMemo(() => {
    const treeGenerator = d3.treemap().size([DIMENSIONS.width, DIMENSIONS.height]).padding(4)
    return treeGenerator(hierarchy)
  }, [hierarchy])

  const handleMouseEnter = (event, leaf) => {
    const containerRect = containerRef.current.getBoundingClientRect()
    setTooltip({
      visible: true,
      content: {
        name: leaf.data.name,
        spend: leaf.data.spend,
        status: leaf.data.status
      },
      x: event.clientX - containerRect.left,
      y: event.clientY - containerRect.top - 10
    })
  }

  const handleMouseMove = (event) => {
    const containerRect = containerRef.current.getBoundingClientRect()
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
      x: event.clientX - containerRect.left,
      y: event.clientY - containerRect.top - 10
    }))
  }

  const handleMouseLeave = () => {
    setTooltip({ visible: false, content: '', x: 0, y: 0 })
  }

  if (noSpendData) {
    return <Card className="card-budget total-card card-shadow mb-3 no-spend-data">Awaiting Spending Data</Card>
  }

  return (
    <div className="position-relative" ref={containerRef}>
      <svg width={DIMENSIONS.width} height={DIMENSIONS.height}>
        {root.leaves().map((leaf, index) => {
          const percentage = Math.floor((leaf.data.spend * 100) / root.value)

          return (
            <g
              key={`block-${index}`}
              onMouseEnter={(e) => handleMouseEnter(e, leaf)}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <rect
                x={leaf.x0}
                y={leaf.y0}
                width={leaf.x1 - leaf.x0}
                height={leaf.y1 - leaf.y0}
                stroke="transparent"
                fill={leaf.data.status === BudgetStatus.APPROVED ? getBlockColor(leaf.data.percentage) : '#A8A8A8'}
                className="block"
              />
              <text
                x={leaf.x0 + 3}
                y={leaf.y0 + 3}
                fontSize={12}
                textAnchor="start"
                alignmentBaseline="hanging"
                fill="white"
                className="font-bold"
              >
                {percentage > MIN_DISPLAY_PERCENTAGE ? leaf.data.name : ''}
              </text>
              <text
                x={leaf.x0 + 3}
                y={leaf.y0 + 18}
                fontSize={12}
                textAnchor="start"
                alignmentBaseline="hanging"
                fill="white"
                className="font-light"
              >
                {percentage > MIN_DISPLAY_PERCENTAGE ? `$${leaf.data.spend}` : ''}
              </text>
            </g>
          )
        })}
      </svg>
      {tooltip.visible && (
        <div
          className="treemap-tooltip"
          style={{
            left: `${tooltip.x}px`,
            top: `${tooltip.y}px`
          }}
        >
          <div>Product: {tooltip.content.name}</div>
          <div>Spend: ${tooltip.content.spend}</div>
          <div>Status: {BudgetStatusLabel[tooltip.content.status]}</div>
        </div>
      )}
    </div>
  )
}
export default TreeMapBudgets
