import { useContext, useEffect, useState } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import LoadingSpinner from 'components/common/LoadingSpinner'
import runFinalCardsUseCase from '../use-cases/run-final-cards-use-case'

import './MonthClose.scss'
import ARInvoice from './ARInvoice'
import FPAndAInvoice from './FPAndAInvoice'
import Roles from 'config/Roles'
import AppContext from 'context/AppContext'
import getActualsForUserNew from 'Actuals/use-cases/get-actuals-for-user-new'

const currentYear = new Date().getFullYear()

function MonthClose() {
  const [actuals, setActuals] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const roles = JSON.parse(localStorage.getItem('roles'))
  const { repoFactory, selectedYear } = useContext(AppContext)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    getActualsForUserNew(
      {
        year: currentYear,
        token: localStorage.getItem('authToken')
      },
      {
        actualsRepo: repoFactory.actualsRepo(),
        observer: {
          receiveActuals: ({ actuals }) => {
            setActuals(actuals)
            setIsLoading(false)
          },
          errorReceivingActuals: () => {
            setActuals([])
            setIsLoading(false)
          }
        }
      }
    )
  }

  const runFinalCards = () => {
    setIsLoading(true)
    runFinalCardsUseCase(
      {
        month: new Date().getMonth(),
        year: selectedYear
      },
      {
        monthCloseRepo: repoFactory.monthCloseRepo(),
        observer: {
          successfulRunFinalCards: (data) => {
            setIsLoading(false)
          },
          errorWhileRunningFinalCards: () => {
            setIsLoading(false)
          }
        }
      }
    )
  }

  return (
    <div className="month-close">
      {isLoading && <LoadingSpinner />}

      {roles.includes(Roles.ADMINS) || roles.includes(Roles.FINANCIAL_ADMINS) ? (
        <Button className="mb-3" onClick={runFinalCards}>
          Run Final Cards
        </Button>
      ) : null}

      <Tabs className="tabs-section" defaultActiveKey={roles.includes(Roles.BU_MANAGERS) ? 'FP&A' : 'AR'}>
        {!roles.includes(Roles.BU_MANAGERS) ? (
          <Tab eventKey="AR" title="AR">
            <ARInvoice actuals={actuals} />
          </Tab>
        ) : null}
        <Tab eventKey="FP&A" title="FP&A">
          <FPAndAInvoice actuals={actuals} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default MonthClose
