import React, { useCallback, useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import { Alert, Button, Card } from 'react-bootstrap'
import Accounts from './Accounts'
import AccountsWithBudgets from './AccountsWithBudgets'
import listAccountMappings from '../use-cases/list-account-mappings'
import reFetchAllAccounts from '../use-cases/re-fetch-all-accounts'

const AllAccountMappingDetails = (props) => {
  const { repoFactory, sorElements, selectedYear, sorElementMapping } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)
  const [allAccounts, setAllAccounts] = useState([])

  const [accountsWithAllocations, setAccountsWithAllocations] = useState(null)
  const [listAllocationsError, setListAllocationsError] = useState(false)
  const [error, setError] = useState(false)
  const [selectedAccountForRemap, setSelectedAccountForRemap] = useState(null)

  const getAccountMapping = useCallback(() => {
    if (selectedYear) {
      listAccountMappings(
        {
          allocationYear: selectedYear
        },
        {
          accountMapperRepoNew: repoFactory.accountMapperRepoNew(),
          observer: {
            elementsReceived: (elements) => {
              setListAllocationsError(false)
              setAccountsWithAllocations(elements.existingMappings)
            },
            noElementsReceived: () => {
              setListAllocationsError(false)
              setAccountsWithAllocations([])
            },
            errorGettingData: () => {
              setListAllocationsError(true)
              setAccountsWithAllocations(null)
            }
          }
        }
      )
    }
  }, [repoFactory, selectedYear])

  useEffect(() => {
    getAccountMapping()
  }, [repoFactory, setListAllocationsError, selectedYear, getAccountMapping])

  const onSuccessfulAllocationChange = () => {
    getAccountMapping()
  }

  const onRemap = (item) => {
    setSelectedAccountForRemap(item)
  }

  const onForceFetch = () => {
    reFetchAllAccounts({
      accountMapperRepo: repoFactory.accountMapperRepoNew(),
      observer: {
        successfullyReFetchingAccounts: () => {
          getAccountMapping()
        },
        errorReFetchingAccounts: () => {}
      }
    })
  }

  return (
    <div className="account-mapper">
      {listAllocationsError && <Alert variant="danger">Error getting account mappings</Alert>}

      {sorElements && (
        <div>
          {isLoading && <LoadingSpinner />}
          {error && <Alert variant="danger">Error getting accounts</Alert>}
          {accountsWithAllocations && (
            <>
              <Button className="mb-3" onClick={onForceFetch}>
                Check for New Account
              </Button>

              <Accounts
                setIsLoading={setIsLoading}
                setError={setError}
                existingAllocations={accountsWithAllocations}
                onSuccessfulAllocationChange={onSuccessfulAllocationChange}
                selectedAccountForRemap={selectedAccountForRemap}
                allocationYear={selectedYear}
                setAllAccounts={setAllAccounts}
                {...props}
              />
            </>
          )}
          {accountsWithAllocations?.length > 0 ? (
            <AccountsWithBudgets
              allAccounts={allAccounts}
              selectedYear={selectedYear}
              repoFactory={repoFactory}
              sorElements={sorElements}
              sorElementMapping={sorElementMapping}
              accountsWithAllocations={accountsWithAllocations}
              allocationYear={selectedYear}
              onRemap={onRemap}
            />
          ) : (
            <>
              <Card className="mt-lg-4">
                <Card.Body>No accounts are mapped to budgets.</Card.Body>
              </Card>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default AllAccountMappingDetails
