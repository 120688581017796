const getAllBudgetsNew = ({ year, token }, { observer, budgetsRepo }) => {
  budgetsRepo.getAllBudgetsNew(
    { year, token },
    {
      success: (budgetsResponse) => {
        if (budgetsResponse.error) {
          observer.failure()
          return
        }

        const budgets = budgetsResponse.data
        observer.success({ budgets })
      },
      failure: (error) => {
        observer.failure()
      }
    }
  )
}

export default getAllBudgetsNew
