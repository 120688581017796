import { createColumnHelper } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const columnHelper = createColumnHelper()

const getColumns = (save, isReadOnly, elementNames) => {
  return [
    columnHelper.accessor('element4', {
      header: () => elementNames.element4
    }),
    columnHelper.display({
      id: 'action',
      header: () => 'Actions',
      cell: ({ row }) => {
        return (
          <div className="action-buttons">
            {!isReadOnly && (
              <button
                className="btn btn-sm btn-remap"
                onClick={() => {
                  save(row.original)
                }}
                disabled={!row.getIsExpanded()}
              >
                Save
              </button>
            )}

            <button
              {...{
                onClick: row.getToggleExpandedHandler()
              }}
              className="btn btn-sm btn-detail "
            >
              Details{' '}
              {row.getIsExpanded() ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
            </button>
          </div>
        )
      }
    })
  ]
}

export default getColumns
