const getSorMapping = (selectedYear, { observer, commonRepo }) => {
  commonRepo.getSorMapping(selectedYear, {
    success: ({ getSorMapping }) => {
      const { data, error } = getSorMapping

      if (error) {
        observer.failure(error)
        return
      }

      observer.success(data)
    },
    failure: (error) => {
      observer.failure(error)
    }
  })
}

export default getSorMapping
