import AuthRepo from './Authentication/repo/auth_repo'
import UserMapperRepo from './Admin/UserMapper/repo/user_mapper_repo'
import UserRepo from './Admin/Users/repo/user_repo'
import ActualsRepo from './Actuals/repo/actuals_repo'
import CommonRepo from './common/repo/common_repo'
import AccountMapperRepoNew from './Admin/AccountMapperNew/repo/account_mapper_repo_new'
import SummaryRepo from './Summary/repo/summary_repo'
import BudgetsRepo from './Budgets/repo/budgets_repo'
import TimePeriodsRepo from './Reporting/PeriodCards/repo/time_periods_repo'
import SpacesRepo from './Spaces/repo/space_repo'
import BIRepo from './Reporting/BusinessIntelligence/repo/bi_repo'
import MonthCloseRepo from 'Admin/MonthClose/repo/month_close_repo'
import SorRepo from 'Admin/Configuration/repo/sor_repo'

function RepoFactory({ httpClient, httpClientConfig, GRAPHQL_URL, GRAPHQL_API_KEY }) {
  this.commonRepo = () => new CommonRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.accountMapperRepoNew = () => new AccountMapperRepoNew({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.authRepo = () => new AuthRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.userMapperRepo = () => new UserMapperRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.timePeriodsRepo = () => new TimePeriodsRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.userRepo = () => new UserRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.actualsRepo = () => new ActualsRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.summaryRepo = () => new SummaryRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.budgetsRepo = () => new BudgetsRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.spacesRepo = () => new SpacesRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.biRepo = () => new BIRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.monthCloseRepo = () => new MonthCloseRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
  this.sorRepo = () => new SorRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient })
}

export default RepoFactory
