import React, { useContext, useEffect } from 'react'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import getPeriodCardsForUser from '../use-cases/get-period-cards-for-user'
import WeeklyAllocationReport from './weekly-allocation-report/WeeklyAllocationReport'
import { useState } from 'react'
import getAccountsMap from 'Summary/use-cases/get-accounts-map'

const PeriodCards = () => {
  const { repoFactory, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [actualsData, setActualsData] = useState(null)
  const [accounts, setAccounts] = useState(null)

  useEffect(() => {
    getAccountsMap({
      accountMapperRepo: repoFactory.accountMapperRepoNew(),
      observer: {
        errorReceivingAccounts: () => {},
        receiveAccounts: (response) => {
          setAccounts(response)
        }
      }
    })
  }, [repoFactory, setAccounts])

  useEffect(() => {
    setIsLoading(true)
    getPeriodCardsForUser(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        observer: {
          receivePeriodCards: (response) => {
            setIsLoading(false)
            setActualsData(response)
          },
          errorReceivingPeriodCards: () => {
            setIsLoading(false)
            setActualsData(null)
          }
        },
        timePeriodsRepo: repoFactory.timePeriodsRepo()
      }
    )
  }, [selectedYear, repoFactory, setActualsData, setIsLoading])

  return (
    <div className="actuals-data">
      {isLoading && <LoadingSpinner />}
      {actualsData && <WeeklyAllocationReport actualsData={actualsData} accountsData={accounts} />}
    </div>
  )
}

export default PeriodCards
