const createInvoiceUseCase = ({ invoice }, { observer, monthCloseRepo }) => {
  monthCloseRepo.createInvoice(
    { invoice },
    {
      success: () => {
        observer.success(true)
      },
      failure: (e) => {
        observer.error('Failed to create invoice')
      }
    }
  )
}

export default createInvoiceUseCase
