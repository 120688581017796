import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { percentFormatter } from 'formatters/percent_formatter'
import Roles from 'config/Roles'
import { BudgetStatus } from 'Budgets/constants/BudgetStatus'
import { Link } from 'react-router-dom'

const columnHelper = createColumnHelper()

const currentMonth = new Date().getMonth()

const getColumns = (elementNames, timeCards) => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  const columns = []

  if (roles.includes(Roles.FINANCIAL_ADMINS) || roles.includes(Roles.BU_MANAGERS)) {
    columns.push(
      columnHelper.accessor('element1', {
        header: () => elementNames.element1
      })
    )
  } else if (roles.includes(Roles.DEPARTMENT_MANAGERS)) {
    columns.push(
      columnHelper.accessor('element2', {
        header: () => elementNames.element2
      })
    )
  } else if (roles.includes(Roles.PORTFOLIO_MANAGERS)) {
    columns.push(
      columnHelper.accessor('element3', {
        header: () => elementNames.element3,
        cell: (info) => {
          const { status } = info.row.original
          const isActionRequired = status.some((item) => item !== BudgetStatus.APPROVED)
          return (
            <div className="d-flex justify-content-between align-items-center fw-medium" style={{ minWidth: '170px' }}>
              {info.getValue()}
              {isActionRequired && (
                <Link to="/budgets" className="badge rounded-pill text-bg-dark m-0 px-3 me-2 text-decoration-none">
                  Budget Task
                </Link>
              )}
            </div>
          )
        }
      })
    )
  } else {
    columns.push(
      columnHelper.accessor('element4', {
        header: () => elementNames.element4,
        cell: (info) => {
          const { status } = info.row.original
          const isActionRequired = status.some((item) => item !== BudgetStatus.APPROVED)
          return (
            <div className="d-flex justify-content-between align-items-center fw-medium" style={{ minWidth: '170px' }}>
              {info.getValue()}
              {isActionRequired && (
                <Link to="/budgets" className="badge rounded-pill text-bg-dark m-0 px-3 me-2 text-decoration-none">
                  Budget Task
                </Link>
              )}
            </div>
          )
        }
      })
    )
  }

  return columns.concat([
    columnHelper.display({
      header: 'Current Period',
      cell: (props) => {
        const { element3, element4, monthToDateUsage } = props.row.original
        let criteriaName = roles.includes(Roles.PORTFOLIO_MANAGERS) ? 'element3' : 'element4'
        let criteriaValue = roles.includes(Roles.PORTFOLIO_MANAGERS) ? element3 : element4

        let total = 0
        if (timeCards && timeCards.length > 0) {
          total = timeCards
            .filter((timeCard) => timeCard[criteriaName] === criteriaValue)
            .reduce((acc, timeCard) => acc + timeCard.total, 0)
        }
        return dollarPriceFormatter(monthToDateUsage - total)
      }
    }),
    columnHelper.accessor('monthToDateUsage', {
      header: () => 'Current Month',
      cell: (info) => {
        return dollarPriceFormatter(info.getValue())
      }
    }),

    columnHelper.display({
      id: 'availableBudgetCurrentMonth',
      header: () => <>Available Budget Current Month</>,
      cell: (props) => {
        const { budgets, monthToDateUsage } = props.row.original

        let approvedBudget = 0
        budgets.forEach((budget) => {
          approvedBudget += budget[`_${currentMonth}`] || 0
        })

        const value = approvedBudget - monthToDateUsage

        return <div className={value < 0 ? 'red' : 'green'}>{dollarPriceFormatter(value)}</div>
      }
    }),

    columnHelper.accessor('mom', {
      header: () => 'Current Trend',
      cell: (info) => {
        const value = info.getValue()
        return <div className={value === null ? '' : value < 0 ? 'green' : 'red'}>{percentFormatter(value)}</div>
      }
    }),
    columnHelper.accessor('annual', {
      header: () => 'Annual',
      cell: (info) => dollarPriceFormatter(info.getValue())
    })
  ])
}

export default getColumns
