export const getInvoicePeriods = () => {
  let now = new Date()
  let previousMonth = now.getMonth() - 1
  let year = now.getFullYear()

  function getLastDay(year, month) {
    return new Date(year, month + 1, 0).getDate()
  }

  let dates = []

  for (let month = previousMonth; month >= 0; month--) {
    let lastDay = getLastDay(year, month)
    let dateStr = `${month + 1}/1/${year.toString().slice(-2)} - ${month + 1}/${lastDay}/${year.toString().slice(-2)}`
    dates.push(dateStr)
  }

  return dates
}
